import { createSvgIcon } from '@mui/material/utils';

const MessagingLogo = createSvgIcon(
    <svg
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 500 500"
        width="100%"
        fill="currentColor"
    >
        <path
            fill="#1E5AE3"
            d="M1.000001,193.000000
            C1.000000,181.645767 1.000000,170.291550 1.363938,158.310120
            C2.836554,151.473160 3.752001,145.221634 5.083504,139.060013
            C20.020447,69.938324 75.974136,16.128540 145.642471,3.775290
            C150.441467,2.924356 155.214752,1.928473 160.000000,1.000000
            C170.354233,1.000000 180.708450,1.000000 191.729401,1.360625
            C216.053787,3.902030 238.449432,10.402237 259.305725,21.801891
            C301.446503,44.835213 329.770142,79.332802 343.898865,125.140945
            C335.747803,125.113991 328.086487,124.883698 320.428223,124.959984
            C265.653320,125.505646 219.006836,146.030960 181.162079,184.900330
            C135.993591,231.291763 118.314125,287.616241 125.461807,351.927124
            C84.289581,351.965546 43.579899,351.965546 1.423823,351.965546
            C21.888311,336.953674 41.136063,322.834381 61.112919,308.180237
            C59.842815,307.312561 58.930103,306.843140 58.204845,306.171326
            C30.372169,280.389587 12.454669,248.956314 4.585297,211.833832
            C3.260314,205.583420 2.188691,199.279297 1.000001,193.000000
        z"/>
        <path
            fill="#1E5AE3"
            d="M501.000000,499.000000
            C500.668793,499.666656 500.456543,500.783905 499.982391,500.909027
            C498.894012,501.196289 497.678070,501.000000 496.513885,501.000000
            C430.655029,501.000000 364.796173,501.000000 298.266296,500.668457
            C295.809570,499.944916 294.035248,499.489044 292.236633,499.170013
            C194.907135,481.908081 130.152878,387.735474 149.342285,290.514740
            C169.609970,187.830963 272.596924,124.317825 373.195282,154.413528
            C438.270203,173.881775 479.217224,218.295639 496.166168,284.304443
            C498.248779,292.415283 499.411163,300.762390 501.000000,309.000000
            C501.000000,319.687561 501.000000,330.375122 500.652954,341.727783
            C499.989594,344.194122 499.610870,345.986847 499.366241,347.797760
            C494.108734,386.719299 477.671295,420.205017 450.380188,448.380432
            C447.845032,450.997772 444.902496,453.220520 441.623657,456.085632
            C449.058441,461.461578 455.494843,466.115601 462.202911,470.994354
            C475.316376,480.479401 488.158203,489.739685 501.000000,499.000000
            M186.833221,266.407898
            C174.338425,295.260101 171.247925,325.336151 177.652115,355.868530
            C192.686859,427.547699 252.924149,475.893097 326.151886,475.991089
            C357.639221,476.033234 389.126648,475.997986 420.614044,475.997955
            C422.197540,475.997955 423.781036,475.997986 426.881836,475.997986
            C417.036713,468.844940 408.436127,462.596069 399.999969,456.466705
            C407.751129,450.633179 415.406494,445.566772 422.220856,439.548126
            C429.058502,433.508881 435.586975,426.950867 441.339661,419.878937
            C483.882294,367.580017 485.156555,291.796936 445.669495,237.445709
            C411.006470,189.734329 348.398010,166.007874 290.986206,179.865829
            C242.450806,191.581238 208.235336,220.757477 186.833221,266.407898
        z"/>
        <path
            fill="#FFFFFF"
            d="M186.964859,266.052795
            C208.235336,220.757477 242.450806,191.581238 290.986206,179.865829
            C348.398010,166.007874 411.006470,189.734329 445.669495,237.445709
            C485.156555,291.796936 483.882294,367.580017 441.339661,419.878937
            C435.586975,426.950867 429.058502,433.508881 422.220856,439.548126
            C415.406494,445.566772 407.751129,450.633179 399.999969,456.466705
            C408.436127,462.596069 417.036713,468.844940 426.881836,475.997986
            C423.781036,475.997986 422.197540,475.997955 420.614044,475.997955
            C389.126648,475.997986 357.639221,476.033234 326.151886,475.991089
            C252.924149,475.893097 192.686859,427.547699 177.652115,355.868530
            C171.247925,325.336151 174.338425,295.260101 186.964859,266.052795
            M385.499512,280.998840
            C391.734253,280.998840 397.968994,280.998840 404.112335,280.998840
            C404.112335,270.594696 404.112335,261.009979 404.112335,251.313354
            C354.310364,251.313354 304.808777,251.313354 255.350769,251.313354
            C255.350769,261.345398 255.350769,271.061615 255.350769,280.998840
            C298.634460,280.998840 341.567047,280.998840 385.499512,280.998840
            M372.500000,311.001160
            C333.445343,311.001160 294.390686,311.001160 255.335236,311.001160
            C255.335236,321.313629 255.335236,331.029297 255.335236,340.612549
            C305.169586,340.612549 354.667084,340.612549 404.130859,340.612549
            C404.130859,330.633423 404.130859,321.042816 404.130859,311.001160
            C393.737488,311.001160 383.618744,311.001160 372.500000,311.001160
            M283.500000,400.998840
            C298.722656,400.998840 313.945312,400.998840 329.152710,400.998840
            C329.152710,390.662079 329.152710,381.072998 329.152710,371.287537
            C304.381439,371.287537 279.870178,371.287537 255.315765,371.287537
            C255.315765,381.286163 255.315765,390.995270 255.315765,400.998840
            C264.586517,400.998840 273.543243,400.998840 283.500000,400.998840
        z"/>
        <path
            fill="#1E5AE3"
            d="M384.999573,280.998840
            C341.567047,280.998840 298.634460,280.998840 255.350769,280.998840
            C255.350769,271.061615 255.350769,261.345398 255.350769,251.313354
            C304.808777,251.313354 354.310364,251.313354 404.112335,251.313354
            C404.112335,261.009979 404.112335,270.594696 404.112335,280.998840
            C397.968994,280.998840 391.734253,280.998840 384.999573,280.998840
        z"/>
        <path
            fill="#1E5AE3"
            d="M373.000000,311.001160
            C383.618744,311.001160 393.737488,311.001160 404.130859,311.001160
            C404.130859,321.042816 404.130859,330.633423 404.130859,340.612549
            C354.667084,340.612549 305.169586,340.612549 255.335236,340.612549
            C255.335236,331.029297 255.335236,321.313629 255.335236,311.001160
            C294.390686,311.001160 333.445343,311.001160 373.000000,311.001160
        z"/>
        <path
            fill="#1E5AE3"
            d="M283.000000,400.998840
            C273.543243,400.998840 264.586517,400.998840 255.315765,400.998840
            C255.315765,390.995270 255.315765,381.286163 255.315765,371.287537
            C279.870178,371.287537 304.381439,371.287537 329.152710,371.287537
            C329.152710,381.072998 329.152710,390.662079 329.152710,400.998840
            C313.945312,400.998840 298.722656,400.998840 283.000000,400.998840
        z"/>
    </svg>,
    'MessagingLogo'
);

export default MessagingLogo;

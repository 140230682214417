/* eslint-disable class-methods-use-this */
import { User, UserManager, UserManagerSettings, WebStorageStateStore } from 'oidc-client-ts';
import { localRoutes, remoteRoutes } from '../constants';

class AuthService {
  userManager?: UserManager;

  initialize() {
    const clientRoot = `${window.location.protocol}//${window.location.hostname}${window.location.port ? `:${window.location.port}` : ''}`;

    const settingsTs: UserManagerSettings = {
      client_id: 'messaging-portal',
      client_secret: 'Gq7HSX9diH60JTksHQJ129qNf0RfgqvzRmXP2f4W',
      redirect_uri: `${clientRoot}/authentication/login-callback`,
      post_logout_redirect_uri: clientRoot,
      response_mode: 'query',
      response_type: 'code',
      scope: 'openid profile email roles crm messaging',
      authority: remoteRoutes.auth,
      loadUserInfo: true,
      silent_redirect_uri: `${clientRoot}/authentication/silent-renew`,
      automaticSilentRenew: true,
      filterProtocolClaims: true,
      userStore: new WebStorageStateStore({ store: window.localStorage })
    };

    this.userManager = new UserManager(settingsTs);
    this.userManager.events.addUserSignedOut(async () => {
      await this.userManager?.removeUser();
    });

    this.userManager.events.addUserLoaded(() => {
      console.log('ON user loaded');
      if (window.location.href.indexOf('login-callback') !== -1) {
        this.navigateToScreen();
      }
    });
    this.userManager.events.addSilentRenewError((e) => {
      console.log('silent renew error', e.message);
    });

    this.userManager.events.addAccessTokenExpired(async () => {
      console.log('token expired');
      await this.signout();
      console.log('Signed out');
    });
  }

  async signinRedirect() {
    const currentPath = window.location.origin + window.location.pathname;
    localStorage.setItem('redirectUri', currentPath);
    console.log('signinRedirect', currentPath);
    await this.userManager?.signinRedirect({});
  }

  signinRedirectCallback() {
    this.userManager?.signinRedirectCallback().then(function (user) {
      const alternatePath = window.location.origin + localRoutes.singleSms;
      const redirectUri = localStorage.getItem('redirectUri');
      console.log('signinRedirectCallback', { user, redirectUri, alternatePath });
      window.history.replaceState({},
        window.document.title,
        redirectUri || alternatePath);
      window.location.replace(redirectUri || alternatePath);
    });
  }

  silentRenewCallback() {
    return this.userManager?.signinSilentCallback();
  }

  startSilentRenew() {
    return this.userManager?.startSilentRenew();
  }

  signinPopupCallback() {
    return this.userManager?.signinPopupCallback();
  }

  async getUser(): Promise<User | undefined> {
    return this.userManager?.getUser();
  }

  navigateToScreen() {
    window.location.replace(localRoutes.singleSms);
  }

  async isAuthenticated() {
    try {
      console.log('###### check auth state ######');
      const user = await this.userManager?.getUser();
      if (user) {
        console.log('###### isAuthenticated ######');
      }
      return !!user;
    } catch (e) {
      return false;
    }
  }

  signinSilent() {
    this.userManager?.signinSilent()
      .then((user) => {
        console.log('signed in', user);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  signinSilentCallback() {
    return this.userManager?.signinSilentCallback();
  }

  signinPopup() {
    return this.userManager?.signinPopup();
  }

  async signout() {
    await this.userManager?.signoutRedirect();
    await this.userManager?.clearStaleState();
  }

  async signoutRedirectCallback() {
    await this.userManager?.signoutRedirectCallback().then(() => {
      // window.location.replace(process.env.REACT_APP_PUBLIC_URL || '');
    });
    await this.userManager?.clearStaleState();
  }
}

const authService = new AuthService();
export default authService;

import 'date-fns';
import React from 'react';
import { useTheme } from '@mui/material/styles';
import { DatePicker } from "@mui/x-date-pickers";
import { hasValue } from "../inputs/inputHelpers";
import dayjs, { Dayjs } from "dayjs";
import { dateFormats } from "../../utils/dateHelpers";


interface IProps {
  onChange: (date: Dayjs) => any
  value: Date | null
  label?: string
  name?: string
  inputFormat?: string
  variant?: 'inline'
  margin?: 'dense' | 'normal' | 'none';
  inputVariant?: 'standard' | 'outlined' | 'filled'
  size?: 'small' | 'medium';
  fullWidth?: boolean;
  disableFuture?: boolean;
  disablePast?: boolean;
}

export default function PDateInput(props: IProps) {
  const theme = useTheme();
  const {
    value = null,
    onChange,
    label,
    size,
    fullWidth = true,
    inputFormat = 'DD/MM/YYYY',
    disableFuture,
    disablePast,
  } = props;

  return (
    <DatePicker
        label={label}
        value={hasValue(value) ? dayjs(value, [...dateFormats, inputFormat]) : null}
        onChange={onChange}
        format={inputFormat}
        disableFuture={disableFuture}
        disablePast={disablePast}
        slotProps={{
          textField: {
            size,
            fullWidth,
          },
          layout: {
            sx: {
              boxShadow: theme.shadows[10],
              borderRadius: '16px'
            }
          }
        }}
    />
  );
}

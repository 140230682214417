import type { FC } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { AppBar, Box, Button, Divider, Hidden, IconButton, Link, Toolbar } from '@mui/material';

import MenuIcon from '../icons/Menu';
import Logo from './Logo';

interface MainNavbarProps {
  onSidebarMobileOpen?: () => void;
}

const MainNavbar: FC<React.PropsWithChildren<MainNavbarProps>> = (props) => {
  const { onSidebarMobileOpen } = props;
  const navigate = useNavigate();
  return (
    <AppBar
      elevation={0}
      sx={{
        backgroundColor: 'background.paper',
        color: 'text.secondary'
      }}
    >
      <Toolbar sx={{ minHeight: 64 }}>
        <Hidden lgUp>
          <IconButton
            color="inherit"
            onClick={onSidebarMobileOpen}
            size="large"
          >
            <MenuIcon fontSize="small" />
          </IconButton>
        </Hidden>
        <Hidden xlDown>
          <RouterLink to="/">
            <Logo
              sx={{
                height: 40,
                width: 40
              }}
            />
          </RouterLink>
        </Hidden>
        <Box sx={{ flexGrow: 1 }} />
        <Hidden lgDown>
          <Link
            color="textSecondary"
            href="https://pay.iotec.io/api-docs/index.html"
            target="_blank"
            underline="none"
            variant="body1"
          >
            Developers
          </Link>
          <Divider
            orientation="vertical"
            sx={{
              height: 32,
              mx: 2
            }}
          />
          <Button
            color="primary"
            size="small"
            variant="contained"
            onClick={() => navigate('/dashboard')}
          >
            Portal
          </Button>
        </Hidden>
      </Toolbar>
      <Divider />
    </AppBar>
  );
};

export default MainNavbar;

import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

const Loading = () => (
  <Box
    width="100%"
    display="flex"
    alignItems="center"
    justifyContent="center"
    height="100%"
  >
    <CircularProgress />
  </Box>
);

export default Loading;

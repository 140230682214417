import React from "react";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import CssBaseline from "@mui/material/CssBaseline";
import Typography from "@mui/material/Typography";
import MessagingLogo from "../../../logos/MessagingLogo";

type Props = {
  title?:string
  children: React.ReactNode;
};
// @ts-ignore
export const ProfileLayout:React.FC<React.PropsWithChildren<Props>> = ({ children, title = "Complete your profile" }) => (
  <Box>
    <Container
      component="main"
      maxWidth="sm"
    >
      <CssBaseline />
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
          width: '100%',
        }}
      >
        <MessagingLogo
            sx={{
              width: 80,
              height: 80,
            }}
        />
        <Typography
          component="h1"
          variant="h6"
          sx={{ mt: 3 }}
        >
          {title}
        </Typography>
        <Box sx={{ mt: 5 }}>
          {children}
        </Box>
      </Box>
    </Container>

  </Box>
);

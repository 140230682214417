import React from 'react';
import { useField } from 'formik';
import { hasValue } from './inputHelpers';
import PComboInput, { PComboProps } from '../plain-inputs/PComboInput';

type XComboProps = Omit<PComboProps, 'onChange' | 'value' | 'onBlur' | 'helperText' | 'showError'>;
const XComboInput = ({ name, ...otherProps }: XComboProps) => {
  const [field, meta, helpers] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error && meta.touched);
  return (
    <PComboInput
      {...otherProps}
      name={name}
      showError={showError}
      helperText={error}
      onChange={(value:any) => helpers.setValue(value)}
      onBlur={() => helpers.setTouched(true)}
      value={field.value}
    />
  );
};

export default XComboInput;

import * as React from 'react';
import { useField } from 'formik';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { hasValue } from './inputHelpers';
import { Option } from './option';

interface IProps {
  label: string
  name: string
  options: Option[]
  multiple?: boolean
  variant?: 'standard' | 'outlined' | 'filled'
  size?: 'small' | 'medium'
  margin?: 'none' | 'dense' | 'normal'
}

const XSelectInput = (props: IProps) => {
  const { name, options, variant = 'outlined', margin = 'normal', ...rest } = props;
  const [field, meta] = useField({ name });
  const error = hasValue(meta.error) ? meta.error : undefined;
  const showError = Boolean(error && meta.touched);
  const inputLabel = React.useRef<HTMLLabelElement>(null);

  return (
    <FormControl
      error={showError}
      fullWidth
      variant={variant}
      margin={margin}
      size={props.size}
    >
      <InputLabel
        htmlFor={name}
        ref={inputLabel}
      >
        {rest.label}
      </InputLabel>
      <Select
        {...rest}
        value={meta.value || (props.multiple ? [] : '')}
        onChange={field.onChange}
        onBlur={field.onBlur}
        fullWidth
        multiple={rest.multiple}
        inputProps={{ name }}
        autoComplete="off"
      >
        {
            options.map(
              (it) => (
                <MenuItem
                  value={it.id}
                  key={it.id}
                >
                  {it.name}
                </MenuItem>
              )
            )
        }
      </Select>
      {
                showError && <FormHelperText>{error}</FormHelperText>
            }
    </FormControl>
  );
};

export default XSelectInput;

import React, { FC, useEffect, useState } from 'react';
import useAuth from 'hooks/useAuth';
import Loading from 'components/loaders/Loading';
import { ContactViewModel, EmailViewModel, PhoneViewModel } from '__generated__/crmTypes';
import EditForm from 'components/dynamic-editor/EditForm';
import { IColumn, InputType } from 'components/dynamic-editor/types';
import { reqString } from 'components/forms/FormValidation';
import { hasNoValue, hasValue, toOptions } from 'components/inputs/inputHelpers';
import { salutationCategories } from '__mocks__/comboCategories';
import { get } from 'utils/ajax';
import Toast from 'utils/Toast';
import { Alert } from '@mui/material';
import { getEmailObj, getPhoneObj } from '../../../types/types';
import { remoteRoutes } from '../../../constants';
import { ProfileLayout } from './ProfileLayout';

type Props = {};

export const formFields: IColumn[] = [
  {
    name: 'salutation',
    label: 'Title',
    inputProps: {
      inputType: InputType.Select,
      options: toOptions(salutationCategories),
      defaultValue: null,
      gridProps: {
        xs: 3, sm: 3
      }
    },
  },
  {
    name: 'firstName',
    label: 'First Name',
    inputProps: {
      inputType: InputType.Text,
      schema: reqString,
      defaultValue: null,
      gridProps: {
        xs: 9, sm: 9
      }
    },
  },
  {
    name: 'middleName',
    label: 'Middle Name',
    inputProps: {
      inputType: InputType.Text,
      defaultValue: null,
      gridProps: {
        xs: 12, sm: 6
      }
    },
  },
  {
    name: 'lastName',
    label: 'Last Name',
    inputProps: {
      inputType: InputType.Text,
      schema: reqString,
      defaultValue: null,
      gridProps: {
        xs: 12, sm: 6
      }
    },
  },
  {
    name: 'email',
    label: 'Email',
    inputProps: {
      inputType: InputType.Text,
      schema: reqString,
      defaultValue: null
    },
  },
  {
    name: 'phone',
    label: 'Phone',
    inputProps: {
      inputType: InputType.Text,
      schema: reqString,
      defaultValue: null
    },
  },
];

export const parseSuccess = (model: ContactViewModel): any => {
  const phoneNumber: PhoneViewModel = getPhoneObj(model);
  const email: EmailViewModel = getEmailObj(model);
  const data = {
    id: model.id,
    firstName: model.person?.firstName,
    lastName: model.person?.lastName,
    phone: phoneNumber.value,
    email: email.value
  };
  const isValid = Object.values(data).every(hasValue);
  return { isValid, parsedData: { ...data, lastName: model.person?.lastName } };
};

export const parseError = (err, user): any => {
  // console.log('parseError', { err, status: err.status });
  
  if (err.status === 404) {
    console.log('Not found', err);
    const { email, name, id } = user;
    const [firstName, mName, ...otherNames] = name?.split(' ').filter(hasValue) ?? [];
    const lastName = hasValue(otherNames) ? otherNames[0] : mName;
    const middleName = hasValue(otherNames) ? mName : null;
    return { isValid: false, parsedData: { email, firstName, lastName, middleName, id } };
  }
  if (err.status === 401) {
    Toast.error('Authentication Error');
    return { error: 'Oops something went wrong.', reload: true };
  }
  Toast.error('Oops something went wrong.');
  return { error: 'Oops something went wrong.', };
};

const ProfileValidator: FC<React.PropsWithChildren<Props>> = ({ children }) => {
  const { user, logoutRedirectCallback } = useAuth();

  const [valid, setValid] = useState<boolean>(false);
  const [error, setError] = useState<string | undefined>();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<any>(true);

  const processSuccess = (resp: ContactViewModel) => {
    const { isValid, parsedData } = parseSuccess(resp);
    setValid(isValid);
    setData(parsedData);
    setLoading(false);
  };

  useEffect(() => {
    if (hasNoValue(user)) return;
    get(`${remoteRoutes.contacts}/${user?.id}`,
      processSuccess, (err) => {
        const { isValid, parsedData, error: e, reload } = parseError(err, user);
        if (reload) {
          console.log('Failed to fetch profile...');
          logoutRedirectCallback();
          return;
        }
        setValid(isValid);
        setData(parsedData);
        setLoading(false);
        setError(e);
      });
  }, []);



  if (hasNoValue(user)) return <>{children}</>;
  if (loading) return <ProfileLayout title="Fetching profile information..."><Loading/></ProfileLayout>;
  if (error) {
    return (
        <ProfileLayout title="Oops! something broke...">
          <Alert
              severity="error"
          >
            {error}
          </Alert>
        </ProfileLayout>
    );
  }
  if (valid) return <>{children}</>;
  return (
      <ProfileLayout>
        <EditForm
            url={remoteRoutes.contactsPerson}
            fields={formFields}
            data={{ ...data }}
            isNew
            isDeletable={false}
            onPostComplete={processSuccess}
        />
      </ProfileLayout>
  );
};

export default ProfileValidator;

import { format, isValid, parseISO } from 'date-fns';
import { hasValue } from 'components/inputs/inputHelpers';

export const dateFormat = 'dd/MM/yyyy';
export const dateTimeFormat = 'dd/MM/yyyy HH:mm';
export const standardDateTimeFormat = 'dd-MM-yyyy HH:mm';
export const standardDateFormat = 'dd-MM-yyyy';
export const isoDateFormat = 'YYYY-MM-DD';
export const dateFormats = ['YYYY-MM-DDTHH:mm:ssZ', 'DD/MM/YYYY',  'YYYY/MM/DD', 'YYYY-MM-DD', 'YYYY.MM.DD', 'DD.MM.YYYY'];

export const getMonthsList = () => {
  const monthsList = [];
  for (let i = 1; i <= 12; i++) {
    monthsList.push(`${i}`.padStart(2, '0'));
  }
  return monthsList;
};

export const getDayList = () => {
  const monthsList = [];
  for (let i = 1; i <= 31; i++) {
    monthsList.push(`${i}`.padStart(2, '0'));
  }
  return monthsList;
};

export const strToDate = (str: string): Date | null => {
  try {
    return parseISO(str);
  } catch (e) {
    return null;
  }
};

export const printEnumInUpper = (value: any): string => {
  if (typeof value === 'string' && hasValue(value)) {
    return value.toUpperCase();
  }
};

export const printDateTime = (value: any): string => {
  if (typeof value === 'string' && hasValue(value)) {
    return printDateTime(strToDate(value));
  }
  if (isValid(value)) return format(value, dateTimeFormat);
  return '';
};

export const isoDateString = (value: any): string => {
  if (typeof value === 'string') {
    return strToDate(value)?.toISOString() || '';
  }
  if (isValid(value)) return value?.toISOString();
  return '';
};

export const printBirthday = (value: any): string => {
  if (typeof value === 'string') {
    return printBirthday(strToDate(value));
  }
  if (isValid(value)) return format(value, 'dd MMM');
  return '';
};

export const printMonth = (value: any): string => {
  if (typeof value === 'string') {
    return printMonth(strToDate(value));
  }
  if (isValid(value)) return format(value, 'MMM');
  return '';
};

export const printDay = (value: any): string => {
  if (typeof value === 'string') {
    return printDay(strToDate(value));
  }
  if (isValid(value)) return format(value, 'dd');
  return '';
};

export const printShortDate = (value: any): string => {
  if (typeof value === 'string') {
    return printShortDate(strToDate(value));
  }
  if (isValid(value)) return format(value, 'dd MMM');
  return '';
};

export const printDayOfMonth = (value: any): string => {
  if (typeof value === 'string') {
    return printDayOfMonth(strToDate(value));
  }
  if (isValid(value)) return format(value, 'dd');
  return '';
};

export const printDate = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value));
  }
  if (isValid(value)) return format(value, dateFormat);
  return '';
};

export const printStdDate = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value));
  }
  if (isValid(value)) return format(value, standardDateFormat);
  return '';
};

export const printStdDatetime = (value: any): string => {
  if (typeof value === 'string') {
    return printDate(strToDate(value));
  }
  if (isValid(value)) return format(value, standardDateTimeFormat);
  return '';
};

import React, { useState } from 'react';
import { Form, Formik, FormikHelpers } from 'formik';

import { Grid, Alert, CircularProgress } from '@mui/material';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import { hasValue } from '../inputs/inputHelpers';
import CodeView from '../CodeView';

interface IProps {
  schema?: any
  onSubmit: (values: any, actions: FormikHelpers<any>) => any
  onCancel?: () => any
  onDelete?: () => any
  isDeletable?: boolean
  debug?: boolean
  loading?: boolean
  children?: React.ReactNode
  initialValues?: any
  showFormErrors?: any
}

const XForm = (props: IProps) => {
  const [count, setCount] = useState<number>(0);

  const handleDelete = (e) => {
    e.preventDefault();
    if (count === 1) {
      setCount(0);
      if (props.onDelete) {
        props.onDelete();
      }
    } else {
      setCount(count + 1);
    }
  };

  const onCancel = (e) => {
    e.preventDefault();
    props.onCancel();
  };

  return (
    <Formik
      initialValues={props.initialValues || {}}
      onSubmit={props.onSubmit}
      validationSchema={props.schema}
      validateOnBlur
      enableReinitialize={false}
    >
      {({ submitForm, isSubmitting, values, errors, touched }) => (
        <Form>
          <Grid
            container
            spacing={0}
          >
            {
                    hasValue(errors) && props.showFormErrors
                    && (
                    <Grid
                      item
                      xs={12}
                    >
                      <Box
                        display="flex"
                        pb={1}
                        justifyContent="center"
                      >
                        <Alert severity="warning">Please provide all required fields(s)</Alert>
                      </Box>
                    </Grid>
                    )
                }
            <Grid
              item
              xs={12}
            >
              {props.children}
            </Grid>
            <Grid
              item
              xs={12}
            >
              <Box
                py={2}
              >
                <Grid
                  container
                  spacing={1}
                  justifyContent="flex-end"
                >
                  { isSubmitting
                  && (
                    <Grid item>
                      <CircularProgress size={30} />
                    </Grid>
                  )}
                  {
                        props.onDelete && props?.isDeletable
                        && (
                        <Grid item>
                          <Button
                            variant="text"
                            color="error"
                            onClick={handleDelete}
                            disabled={isSubmitting}
                          >
                            {count === 1 ? '! Confirm' : 'Delete'}
                          </Button>
                        </Grid>
                        )
                    }
                  {
                      props.onCancel
                      && (
                      <Grid item>
                        <Button
                          variant="text"
                          color="primary"
                          onClick={onCancel}
                          disabled={isSubmitting || props.loading}
                        >
                          Cancel
                        </Button>
                      </Grid>
                      )
                  }
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submitForm}
                      disabled={isSubmitting || props.loading}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {
                    props.debug
                    && (
                    <Grid
                      item
                      xs={12}
                    >
                      <CodeView data={{ values, errors, touched }} />
                    </Grid>
                    )
                }
          </Grid>
        </Form>
      )}
    </Formik>
  );
};

export default XForm;

import axios from 'axios';
// import KeycloakAuthService from './KeycloakAuthService';

const HttpMethods = {
  GET: 'GET',
  POST: 'POST',
  DELETE: 'DELETE',
};

const axiosInstance = axios.create();

const configure = () => {
  // axiosInstance.interceptors.request.use((config:any) => {
  //   if (KeycloakAuthService.isLoggedIn()) {
  //     const cb = () => {
  //       config.headers.Authorization = `Bearer ${KeycloakAuthService.getToken()}`;
  //       return Promise.resolve(config);
  //     };
  //     return KeycloakAuthService.updateToken(cb);
  //   }
  // });
};

const getAxiosClient = () => axiosInstance;

const HttpService = {
  HttpMethods,
  configure,
  getAxiosClient,
};

export default HttpService;
